import { PaginatedResponse } from '@/model/api/PaginatedResponse';
import { plainToClass } from 'class-transformer';
import { CRUD } from './base/crud';
import {Material} from "@/model/api/Material";

class MaterialsService extends CRUD<Material, any> {
    readonly endPoint = `materials`;

    public async index(params?: any): Promise<PaginatedResponse<Material>> {
        const response = await super.index(params);

        response.data = plainToClass(Material, response.data);

        return response;
    }

    public async getById(id: number): Promise<Material> {
        const response = await super.getById(id);

        return plainToClass(Material, response);
    }
}

export const materialsService = new MaterialsService();



