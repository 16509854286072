import { SortMeta, SortOrderEnum } from "../ui/PrimeVueTypes";

export class BaseApiFilter {
    /**
     * #### Indice del primo record da visualizare    
     * Es. Tabella con rows = 20   
     * - Pag 1 -> first = 0   
     * - Pag 2 -> first = 20  
     */
    first: number = 0;

    /**
    * #### Numero dei record da visualizzare
    */
    rows: number = 10;
    per_page: number = 10; 

    page: number = 0; 

    //------ SORT SINGOLO --------- //
    /**
     * #### Chiave su cui effettuare l'ordinamento
     */
    sortField: string = null;

    /**
     * #### Verso ordinamento  
     * 1 ASC  
     * -1 DESC 
     */
    sortOrder: SortOrderEnum = SortOrderEnum.ASC;

    //------ SORT MULTIPLO ---------- //

    /**
     * #### Raccolta di chiavi e verso dell'ordinamento
     */
    multisSortMeta: SortMeta[];
    
    filters: any;

    q?: string = null; 

    constructor(data?: BaseApiFilter) {
        if (data) {
            Object.assign(this, data);
        }
    }

    static fromGlobal(value: string, other?: any){
        let x = new BaseApiFilter();

        x = {
            ...x,
            per_page: 50, 
            filters: {
                global: {
                    value, matchMode: 'STARTS_WITH' 
                }
            },
            ...(other || {})
        };

        return x; 
    }
}